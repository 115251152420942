import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import Section from '@latitude/section';
import Layout from '@/components/layout';
import AchPageHeaderSection from './_ach-page-header';
import GemMicrositeHeader from '../_gem-microsite-header';
import IPhoneNotSupportedSection from '@/pages/_iphoneNotSupported';

const urlVixVerify =
  'https://au.vixverify.com/sa/customers/gecapital-ach/home.seam?ui=gemau';

function AchAdditionalDetailsPage(props) {
  const [urlFrame, setUrlFrame] = useState(null);

  function fetchIframeSource() {
    if (window?.location?.search) {
      const queryParameters = new URLSearchParams(window.location.search);
      const applicationId = queryParameters.get('applicationid');

      if (applicationId) {
        return urlVixVerify + '&applicationid=' + applicationId;
      }
    }
    return urlVixVerify;
  }

  useEffect(() => {
    const iframeSource = fetchIframeSource();
    setUrlFrame(iframeSource);
  }, []);

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/gem-visa/addcard/ach-form/"
          />
          <meta
            name="description"
            content="Add an additional cardholder to your Latitude Gem Visa card"
          />
          <title>Additional Card Holder Details | Gem Visa card</title>
        </Helmet>

        <AchPageHeaderSection />
        <GemMicrositeHeader />
        <IPhoneNotSupportedSection />
        <Section>
          <div
            css={{ clear: 'both', margin: '10px 0' }}
            className="lfs-iframe-vixverify"
          >
            {urlFrame && (
              <iframe
                css={{
                  width: '100%',
                  minHeight: '800px',
                  border: 'none',
                  padding: '10px'
                }}
                title="Verify Your Identity"
                src={urlFrame}
              />
            )}
          </div>
        </Section>
      </main>
    </Layout>
  );
}

export default AchAdditionalDetailsPage;
